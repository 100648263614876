import React from 'react'

export default function Other() {
  return (
    <div>
      <div className="otherPage">
        <section id="Other">
          <div className="who">
            <img src="who.png" alt="" />
            <div className="block">
              <h1>Who We Are?</h1>
              <p className="whoPara"><strong>R K SOFTWARE SOLUTIONS</strong> is an IT services provider company dedicated to delivering cutting-edge digital solutions. Our services include web development, application development, digital marketing, and data entry services. With a team of skilled professionals, we strive to provide innovative and efficient solutions to meet our client's needs.</p>
            </div>
          </div>
          <hr />
          <div className="why">
            <h1>Why Choose Us?</h1>
            <p className="whoPara">
              At <strong>R K SOFTWARE SOLUTIONS</strong>, we understand that choosing the right IT services partner is critical to your business success. Here’s why businesses trust us to deliver cutting-edge solutions:</p>
            <div className="whyChoose">
              <div className="choose">
                <h4>1. Comprehensive IT Solutions</h4>
                <p>We offer a wide range of services, including:</p>
                <p><span>Web Development, Application Development, Digital Marketing, Search Engine Optimization and Data Entry Services.</span></p>
              </div>
              <div className="choose">
                <h4>2. Experienced & Skilled Team</h4>
                <p>Our team consists of highly skilled professionals with expertise in the latest technologies and industry best practices. With years of experience, we deliver solutions that exceed expectations.</p>
              </div>
              <div className="choose">
                <h4>3. Customer-Centric Approach</h4>
                <p>We believe in building long-term partnerships with our clients. We take the time to understand your business, goals, and challenges to provide personalized solutions that drive success.</p>
              </div>
              <div className="choose">
                <h4>4. Commitment to Quality</h4>
                <p>Quality is non-negotiable. We adhere to rigorous quality standards and ensure every project is delivered with precision, reliability, and excellence.</p>
              </div>
              <div className="choose">
                <h4>5. Transparent Communication & Support</h4>
                <p>We maintain clear and open communication throughout the project lifecycle. Our dedicated support team is available to assist you with any queries or issues, ensuring a seamless experience.</p>
              </div>
              <div className="choose">
                <h4>6. Affordable & Flexible Pricing</h4>
                <p>We offer competitive pricing without compromising on quality. Our flexible engagement models allow you to choose the best option for your business needs and budget.</p>
              </div>
              <hr />
              <p className="whoPara">By partnering with us, you gain a trusted IT services provider committed to driving your business success. Let’s collaborate to turn your ideas into reality and unlock new growth opportunities.</p>
            </div>
          </div>

        </section>

      </div>

    </div>
  )
}
