import React, { useState } from 'react'
// import { Link } from "react-router-dom";

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <nav className="navbar">
            <div className="brand-title">
                <img src="logo_2908_0405.jpeg" alt="" />
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <div className={`nav-links ${isOpen ? "active" : ""}`}>
                <a href="/">Home</a>
                <a href="/about">About</a>
                <a href="/services">Services</a>
                <a href="/career">Career</a>
                <a href="/contact">Contact</a>
                <div className="nav-item mx-5">
                    <a href="tel:+919370470745" className="btn btn-outline-success mx-2">Call Us Now</a>
                    <a href="mailto:contact@rksoftwaresolutions.net?subject=For Enquiry" className="btn btn-outline-success mx-1">Email Us</a>
                </div>
            </div>

        </nav>


    )
}











































// <>
//     <div id='navbar'>
//         <nav className="navbar navbar-expand-lg fixed-top bg-light navbar-dark bg-body-tertiary">
//             <div className="container-fluid">
// {/* <a className="navbar-brand" href="#">R K SOFTWARE SOLUTIONS</a> */}
// <img src="logo_2908_0405.jpeg" alt="" />
// <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//     <span className="navbar-toggler-icon"></span>
// </button>
// <div className="collapse navbar-collapse" onClick={toggleMenu} id="navbarSupportedContent">
//     <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//         <li className="nav-item">
//             <Link className="nav-link" aria-current="page" to="/">Home</Link>
//         </li>
//         <li className="nav-item">
//             <Link className="nav-link" to="/about">About Us</Link>
//         </li>
//         <li className="nav-item">
//             <Link className="nav-link" to="/services">Services</Link>
//         </li>
//         <li className="nav-item">
//             <Link className="nav-link" to="/career">Career</Link>
//         </li>
//         <li className="nav-item">
//             <Link className="nav-link" to="/contact">Contact Us</Link>
//         </li>
//     </ul>
// {/* <form className="d-flex" role="search">
//     <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
//     <button className="btn btn-outline-success" type="submit">Search</button>
// </form> */}
//                     <div className="nav-item my-3">
//                         <a href="tel:+919370470745" className="btn btn-outline-success mx-2">Call Us Now</a>
//                         <a href="mailto:contact@rksoftwaresolutions.net?subject=For Enquiry" className="btn btn-outline-success mx-1">Email Us</a>
//                     </div>
//                 </div>
//             </div>
//         </nav>

//     </div>

// </>

