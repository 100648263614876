import React from 'react'
import { a } from "react-router-dom";

export default function Footer() {
  return (
    <div>
        {/* <!-- For Footer Page --> */}
    <footer className="footerPage">
        <div className="footer-cols">
            {/* <!-- For About Us --> */}
            <ul>
                <li>About Us</li>
                <li>
                    <a href="/about">Mission</a>
                </li>
                <li>
                    <a href="/about">Vision</a>
                </li>
                <li>
                    <a href="/about">Values</a>
                </li>
            </ul>

            {/* <!-- For Services --> */}
            <ul>
                <li>Services</li>
                <li>
                    <a href="/services">Web Development</a>
                </li>
                <li>
                    <a href="/services">Android Application Development</a>
                </li>
                <li>
                    <a href="/services">IoT Application Development</a>
                </li>
                <li>
                    <a href="/services">Digital Marketing</a>
                </li>
                <li>
                    <a href="/services">Search Engine Optimization</a>
                </li>
                <li>
                    <a href="/services">Data Entry Services</a>
                </li>
            </ul>


            {/* <!-- For Career --> */}
            <ul>
                <li>Career</li>
                <li>
                    <a href="/career">Vacancy</a>
                </li>
                <li>
                    <a href="/career">Internships</a>
                </li>
            </ul>

            {/* <!-- For Contact --> */}
            <ul>
                <li>Contact Us</li>
                <li>
                    {/* <a href="/contact">Contact R K Software Solutions</a> */}
                    {/* <!-- <address>Khadgaon Road, Tekdi Wadi, Nagpur, Maharashtra,  */}
						{/* Contact: 9370470745,</address> --> */}
                        {/* BEHIND PERFICIENT, <br />
                        Dr. BABASAHEB AMBEDKAR LIBRARY, <br />
                        SUBHASH NAGAR, <br />
                        NAGPUR */}
                        Phone: +919370470745<br />
                        Email: contact@rksoftwaresolutions.net
                </li>
                <li>
                    <li>Social Links</li>
                    <div className="social-icon">
                        <a href="https://www.aedin.com/in/r-k-software-solutions-529a672b4/" rel='noreferrer' target="_blank">
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                        <a href="https://www.instagram.com/rksoftwaresolutions/" rel='noreferrer' target="_blank">
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                        {/* <!-- <a href="#"> */}
								{/* <i className="fa-brands fa-youtube"></i> */}
							{/* </a> --> */}
                    </div>
                </li>
            </ul>
        </div>
        
        <hr/>
        <div className="footer-bottom">
            &copy; 2023-2024 | R K Software Solutions | All Rights Reserved
        </div>
    </footer>
      
    </div>
  )
}
