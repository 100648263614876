import React from 'react'

export default function Services() {
    return (
        <div>
            {/* <!-- For Services Page--> */}
            <div className="servicePage">
                <section id="Services">
                    <h1>Our Services</h1>
                    <p>At R K SOFTWARE SOLUTIONS, we specialize in delivering high-quality solutions tailored to meet your business needs. Our core services include:</p> 
                    {/* Web Development, where we create modern, responsive, and user-friendly websites to enhance your online presence. Our Application Development expertise ensures robust, scalable, and custom-built applications designed to streamline your operations. We also offer Data Entry Services, providing accurate and efficient data management solutions that save you time and resources. Additionally, our Digital Marketing services help you reach and engage your target audience, driving growth and maximizing your ROI. Partner with us to transform your ideas into reality and achieve your business goals. */}
                    <hr />
                    <div className="service">
                        <i className="fa-solid fa-laptop"></i>
                        <h2>Web Development</h2>
                        {/* <!-- <p>Write well-designed, testable, efficient codeby using web development best practices. Create basic website layouts and user interfaces with HTML and CSS. <span id="dots">...</span><span id="more">Integrate data from various back-end web services and databases. Work with stakeholders to gather and refine specifications and requirements based on technical needs.</span></p> --> */}
                        <p>We create responsive, high-performance websites tailored to your business needs. Our expertise
                            includes front-end and back-end development, e-commerce solutions, and CMS integration.</p>

                        {/* <!-- <button onclick="myFunction()" id="myBtn">Read More</button> --> */}
                    </div>
                    <div className="service">
                        <i className="fa-brands fa-android"></i>
                        <h2>Android Application Development</h2>
                        {/* <!-- <p>We are always looking for ways to improve the user experience and optimize performance. We also have a knack for finding innovative solutions to complex problems. <span id="dot">...</span><span id="mor">Our technical skills, combined with our analytical and problem-solving abilities, make us an ideal for Android development.</span></p> --> */}
                        <p>Our team develops innovative mobile and desktop applications to enhance your business operations. We
                            specialize in both Android and iOS platforms, using the latest technologies to deliver exceptional
                            user experiences.</p>

                        {/* <!-- <button onclick="myFunctio()" id="myBt">Read More</button> --> */}
                    </div>
                    <div className="service">
                        <i className="fa-brands fa-connectdevelop"></i>
                        <h2>IoT Application Development</h2>
                        {/* <!-- <p>IoT or Internet of Things refers to the collective network of connected devices and the technology that facilitates communication between devices and the cloud, <span id="dott">...</span><span id="morr">as well as between the devices themselves. What is IoT? The Internet of Things (IoT) describes the network of physical objects—“things”—that are embedded with sensors, software, and other technologies for the purpose of connecting and exchanging data with other devices and systems over the internet. So we are able to fulfill complete your IoT integration and application</span></p> --> */}
                        <p>Develop intuitive and user-friendly applications that enhance the interaction between users and
                            devices. Our developers create mobile and web applications with seamless user interfaces and rich
                            features, providing an optimal user experience.</p>

                        {/* <!-- <button onclick="myFunctii()" id="myBtt">Read More</button> --> */}
                    </div>
                    <div className="service">
                        <i className="fa-solid fa-tachograph-digital"></i>
                        <h2>Digital Marketing</h2>
                        {/* <!-- <p>Digital marketing is the promotion of brands to connect with potential customers using the internet and other forms of digital communication. <span id="dottt">...</span><span id="morrr">This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel. We plans and executes all web, SEO/SEM, database marketing, email, social media, and display advertising campaigns. We can designs, builds, and maintains your social media presence.</span></p> --> */}
                        <p>Boost your online presence with our comprehensive digital marketing services. We offer SEO, SEM,
                            social media marketing, content creation, and analytics to help you reach your target audience
                            effectively. We can designs, builds and maintains your social media presence.</p>

                        {/* <!-- <button onclick="myFunctiii()" id="myBttt">Read More</button> --> */}
                    </div>
                    <div className="service">
                        <i className="fa-solid fa-ranking-star"></i>
                        <h2>Search Engine Optimization</h2>
                        {/* <!-- <p>Search engine optimization (SEO) is the practice of orienting your website to rank higher on a search engine results page (SERP) so that you receive more traffic. <span id="dotttt">...</span><span id="morrrr">The aim is typically to rank on the first page of Google results for search terms that mean the most to your target audience.</span></p> --> */}
                        <p>We understand that visibility in search engines is crucial for driving traffic, increasing brand
                            awareness, and boosting sales. Our expert SEO services are designed to enhance your online presence,
                            ensuring your business stands out in the competitive digital landscape.</p>

                        {/* <!-- <button onclick="myFunctiiii()" id="myBtttt">Read More</button> --> */}
                    </div>
                    <div className="service">
                        <i className="fa-solid fa-book"></i>
                        <h2>Data Entry Services</h2>
                        {/* <!-- <p>We provide the data entry services which includes basic online, conversion, transcription work <span id="dottttt">...</span><span id="morrrrr">and web searching, data scrapping and all type of data entry services we provide.</span></p> --> */}
                        <p>Our data entry services ensure accurate and efficient data management. We handle large volumes of
                            data with precision, enabling you to focus on your core business activities. Services which includes conversion, transcription, web searching, data scrapping and all type of data entry.</p>
                        {/* <!-- <button onclick="myFunctiiiii()" id="myBttttt">Read More</button> --> */}
                    </div>
                </section>
            </div>

        </div>
    )
}
