import React from 'react'

export default function Home() {
  // let i = 0;
  // let txt = 'R K SOFTWARE SOLUTIONS';
  // let speed = 50;

  // const typeWriter = () => {
  //   if (i < txt.length) {
  //     document.getElementById("type").innerHTML += txt.charAt(i);
  //     i++;
  //     setTimeout(typeWriter, speed);
  //   }
  // }

  return (
    <div id="showcase">
      <section id="Home">
        <div className="container">
          <h1>Welcome to</h1>
          {/* <p id="type"></p> */}
          <h2>R K SOFTWARE SOLUTIONS</h2>
          <p>Your partner in digital transformation.</p>
          {/* <p>R K SOFTWARE SOLUTIONS is a leading IT company dedicated to delivering cutting-edge digital
            solutions. Our services include web development, application development, digital marketing, and
            data entry services. With a team of skilled professionals, we strive to provide innovative and
            efficient solutions to meet our clients' needs. </p> */}

          <a href="mailto:contact@rksoftwaresolutions.net?subject=For Enquiry" className="btn btn-outline-success mx-2">Enquire Now</a>
        </div>
      </section>
    </div>
  )
}
