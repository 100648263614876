import React from 'react'

export default function About() {
  return (
    <>
        {/* <!-- For About Us Page --> */}
    <div className="aboutPage bd-masthead">
        <section id="About_Us">
            <h2>About Us</h2>
            <p>R K SOFTWARE SOLUTIONS is an IT services provider company dedicated to delivering cutting-edge digital solutions.
                Our services include web development, application development, digital marketing, and data entry
                services. With a team of skilled professionals, we strive to provide innovative and efficient solutions
                to meet our client's needs.</p>
        </section>
        <hr />
        <section id="mission">
            <h2>Our Mission</h2>
            <div className="mission">
                <p>Our mission is to empower businesses through innovative technology solutions. We aim to deliver
                    high-quality, cost-effective services that meet the evolving needs of our clients, helping them
                    achieve their business objectives and stay ahead in the competitive market.</p>
            </div>
        </section>
        <section id="vision">
            <h2>Our Vision</h2>
            <div className="vision">
                <p>Our vision is to be a global leader in the IT industry, renowned for our commitment to excellence and
                    customer satisfaction. We strive to drive digital transformation and create a positive impact on
                    businesses and communities worldwide.</p>
            </div>
        </section>
        <section id="values">
            <h2>Our Values</h2>
            <div className="values">
                <p><strong>Integrity:</strong> We uphold the highest standards of integrity in all our actions.</p>
                <p><strong>Customer Focus:</strong> We are dedicated to meeting our customers' needs and exceeding their
                    expectations.</p>
                <p><strong>Innovation:</strong> We embrace innovation and encourage creative solutions to challenges.
                </p>
                <p><strong>Collaboration:</strong> We believe in the power of teamwork and collaboration to achieve
                    great results.</p>
                <p><strong>Excellence:</strong> We strive for excellence in everything we do, delivering high-quality
                    services and solutions.</p>
            </div>
        </section>
    </div>
    </>
  )
}
