import React from 'react'

export default function Contact() {
  return (
    <div>
        {/* <!-- For Contact Us Page --> */}
    <div className="contactPage">
        <section id="Contact_Us">
            <h2>Contact Us</h2>
            <p className="contPara">If you have any questions or need more information, please contact us:</p>
            <hr />
            <ul className="contact-info">
                <li>Email: <a href="mailto:contact@rksoftwaresolutions.net">contact@rksoftwaresolutions.net</a></li>
                <li>Phone: +919370470745</li>
                {/* <li>Address: BEHIND PERFICIENT, Dr. BABASAHEB AMBEDKAR LIBRARY, SUBHASH NAGAR, NAGPUR</li> */}
            </ul>
        </section>
    </div>
      
    </div>
  )
}
