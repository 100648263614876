import React from 'react'

export default function Career() {
    return (
        <div>
            {/* <!-- For Career page --> */}
            <div className="careerPage">
                <section id="Career">
                    <h2>Career</h2>
                    <p className="centPara">We are looking for talented individuals to join our team. Explore our current
                        openings:</p>
                        <hr />
                    <ul className="career-list">
                        <li>
                            <h3>Web Developer</h3>
                            <p>We are looking for a skilled Web Developer proficient in HTML, CSS, JavaScript, and modern web
                                development frameworks. The ideal candidate will have experience in creating responsive,
                                high-performance websites.</p>
                            <a href="mailto:contact@rksoftwaresolutions.net?subject=Application for Web Developer"
                                className="apply-btn">Apply Now</a>
                        </li>
                        <li>
                            <h3>App Developer</h3>
                            <p>Join our team as an App Developer! We seek an individual with expertise in Android and iOS
                                development, knowledge of programming languages such as Swift and Kotlin, and a passion for
                                creating innovative mobile applications.</p>
                            <a href="mailto:contact@rksoftwaresolutions.net?subject=Application for App Developer"
                                className="apply-btn">Apply Now</a>
                        </li>
                        <li>
                            <h3>Digital Marketer</h3>
                            <p>We are in search of a creative Digital Marketer who can drive our online presence. The ideal
                                candidate will have experience in SEO, SEM, social media marketing, content creation, and data
                                analytics to enhance our digital marketing efforts.</p>
                            <a href="mailto:contact@rksoftwaresolutions.net?subject=Application for Digital Marketer"
                                className="apply-btn">Apply Now</a>
                        </li>
                        <li>
                            <h3>Data Entry Operator</h3>
                            <p>We are looking for a detail-oriented Data Entry Operator to join our team. The candidate should
                                have excellent typing skills, attention to detail, and the ability to manage and organize large
                                amounts of data accurately and efficiently.</p>
                            <a href="mailto:contact@rksoftwaresolutions.net?subject=Application for Data Entry Operator"
                                className="apply-btn">Apply Now</a>
                        </li>
                    </ul>
                </section>
            </div>

        </div>
    )
}
